<template>
  <!-- 服务能力 -->
  <div class="zt-block">
    <div class="zt-block-head">服务能力</div>
    <div class="zt-block-content">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="150px"
      >
        <el-form-item
          v-for="(Ability, index) in ruleForm.Abilitys"
          :key="index"
          :prop="'Abilitys.' + index + '.value'"
          :rules="rules.ability"
          :label="Ability.name"
          :required="Ability.required"
        >
          <TagSelect v-model="Ability.value" :tags="Ability.tags" :limit="Ability.limit"></TagSelect>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getDesignCompServiceAbility,
  getFactoryServiceAbility
} from "@/service/company";
import {getCats} from '@/service/item';
import TagSelect from "@/components/TagSelect";

export default {
  components: {
    TagSelect
  },
  model: {
    prop: "ability",
    event: "change"
  },
  props: ["ability", "compType"],
  data() {
    return {
      abilityKeys: {
        // 设计公司
        range: {name:"服务范围",required:true},
        // 工厂
        jgsx: {name:"生产属性",required:true},
        mczl: {name:"主要木材",required:true},
        zdcp: {name:"主打产品",required:true},
        wx: {name:"外协加工",required:false},

        xsbq: {name:"销售信息标签",required:true},
        fwbq: {name:"服务标签",required:true,limit:8}
        // 销售公司
      },
      abilityList: {},
      ruleForm: {
        Abilitys: []
      },
      rules: {
        ability: [
          {
            // required: true,
            validator: (rule, value, callback) => {
              let _idx = rule.field.split(".")[1];
              let field = this.ruleForm.Abilitys[_idx];
              if (field.required==false || (value && value.length > 0)) {
                callback();
              } else {
                callback(new Error(`${field.name} 至少选择一项!`));
              }
            },
            trigger: "blur"
          }
        ]
        // intro: [{ required: true, message: '请输入项目描述', trigger: 'blur' }],
        // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
      }
    };
  },
  async created() {
    // let abilityList={
    //   range:["家具设计","3D渲染","视频拍摄"]
    // };
    // 设计公司
    if (this.compType == 2) {
      this.abilityList = await getDesignCompServiceAbility();
    }
    // 工厂
    if (this.compType == 1) {
      this.abilityList = await getFactoryServiceAbility();
      await this.addZdcp();
    }
    // 销售公司
    if (this.compType == 3) {
      await this.addZdcp();
    }
    for(let key of Object.keys(this.abilityKeys)){
      let tags = this.abilityList[key];
      if(tags) this.ruleForm.Abilitys.push({
        id: key,
        name: this.abilityKeys[key].name,
        tags: tags,
        required:this.abilityKeys[key].required,
        limit:this.abilityKeys[key].limit,
        value: this.ability ? this.ability[key] || [] : []
      });
    }
  },
  methods: {
    async addZdcp(){
      let cats = await getCats();
      let zdcp = cats.filter(r=>{
        return r.parentId==null;
      }).map(r=>{
        return {
          k:r.id,
          v:r.name
        };
      });
      this.abilityList=Object.assign({
        zdcp:zdcp
      },this.abilityList);
    },
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid, obj) => {
          if (valid) {
            this.$emit(
              "change",
              this.ruleForm.Abilitys.reduce((pre, cur) => {
                pre[cur.id] = cur.value;
                return pre;
              }, {})
            );
            resolve(true);
          } else {
            return reject(
              new Error("服务能力:" + obj[Object.keys(obj)[0]][0].message)
            );
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>

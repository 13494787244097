<template>
  <div class="zt-block">
    <div class="zt-block-head">案例展示</div>
    <div class="zt-block-content">
      <div v-for="(_case,_index) of cases_" :key="_case.id" class="inner-row">
        <el-button type="text" icon="el-icon-delete" class="del-btn" @click="del(_index)">删除</el-button>
        <Case :case.sync="cases_[_index]" :ref="'case_'+_index"></Case>
      </div>
      <el-button type="text" icon="el-icon-circle-plus-outline"  @click="addCase">添加案例</el-button>
    </div>
  </div>
</template>
<script>
import Case from "./Case";
export default {
  components: {
    Case
  },
  model: {
    prop: "cases",
    event: "change"
  },
  props: ["cases"],
  data() {
    return {
      cases_: this.cases ? this.cases.map((item,idx)=>{
        if(!item.id) item.id=idx;
        return item;
      }):[]
    };
  },
  created() { },
  methods: {
    addCase() {
      if (this.cases_) {
        this.valid()
          .then(() => {
            this.cases_.push({id:(new Date()).getTime()});
            this.$emit("change", this.cases_);
          })
          .catch(err => {});
      } else {
        this.cases_ = [{id:(new Date()).getTime()}];
        this.$emit("change", this.cases_);
      }
    },
    async valid() {
      this.$emit("change", this.cases_);
      if (this.cases_ && this.cases_.length > 0) {
        for (let i = 0; i < this.cases_.length; i++) {
          let refname = "case_" + i;
          await this.$refs[refname][0].validForm();
        }
      }/* else{
        throw new Error("至少填写一个案例展示");
      } */
    },
    del(_index){
      this.cases_.splice(_index,1);
    }
  }
};
</script>
<style scoped>
.inner-row{
  position: relative;
}
.inner-row:not(:first-child){
  border-top: 1px solid #eee;
  padding-top: 30px;
}
.del-btn {
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 999;
}
.inner-row:first-child .del-btn{
  top:0;
}
</style>
<template>
  <div class="zt-block">
    <div class="zt-block-content">
      <span
        v-if="rz.stat"
        style="font-size:24px;color:rgba(43,186,174,1);font-weight:bold;"
        >已实地认证</span
      >
      <span v-else style="font-size:24px;color:red;font-weight:bold;"
        >未实地认证</span
      >
      &nbsp;
      <el-popover placement="top-start" trigger="hover" width="400">
        <div>
          <p>
            <b>什么是实地认证？</b>
            <br />实地检测工厂，包括但不限于工艺能力、工厂实际图片、销售情况等等
          </p>

          <p>
            <b>实地认证好处？</b>
            <br />获得实地认证标志后，将得到采购商信赖
          </p>

          <p>
            <b>实地认证到期？</b>
            <br />每次实地认证的有效期为1年，若有内容更新/认证与实际不符，可联系销售人员，重新认证
          </p>
        </div>
        <i
          class="el-icon-warning"
          style="color:rgba(1,111,255,1);font-size: 20px;"
          slot="reference"
        ></i>
      </el-popover>
      <span v-if="rz.stat" style="font-size:12px;color:rgba(128,128,128,1);">&nbsp;&nbsp;到期日:{{ rz.expired_at | moment('YYYY年MM月')}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["rz"],
  data() {
    return {};
  },
  watch: {},
  async created() {},
  mounted() {},
  methods: {}
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>

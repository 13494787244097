<template>
  <div class="zt-block">
    <div class="zt-block-head">相关资料</div>
    <div class="zt-block-content">
      <el-form
        v-if="ruleForm"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item label="获奖荣誉证书">
          <UploadImgs v-model="ruleForm.honor" prefix='comp/aptitude/ziliao/honor' :limit="10"></UploadImgs>
        </el-form-item>
        <el-form-item label="企业质量体系证">
          <UploadImgs v-model="ruleForm.iso" prefix='comp/aptitude/ziliao/iso' :limit="5"></UploadImgs>
        </el-form-item>
        <el-form-item label="官网链接" prop="url">
          <el-input style="width:640px;" v-model="ruleForm.site"></el-input>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.ability_make"
          label="工艺能力"
          prop="ability_make"
        >
          <Gynl v-model="ruleForm.ability_make"></Gynl>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import UploadImgs from "@/components/upload/UploadImgs.vue";
import Gynl from "./Gynl";
export default {
  components: { UploadImgs, Gynl },
  model: {
    prop: "relation",
    event: "change"
  },
  props: ["relation"],
  data() {
    return {
      ruleForm: null,
      rules: {
        // n_employee: [
        //   { required: true, message: "请输入总人数", trigger: "blur" }
        // ],
        // reg_date: [
        //   { required: true, message: "请输入成立日期", trigger: "blur" }
        // ]
        // intro: [{ required: true, message: '请输入项目描述', trigger: 'blur' }],
        // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
      }
    };
  },
  // watch: {
  //   "relation":{
  //     handler(newName, oldName) {
  //       this.ruleForm = this.relation || {};
  //       if(!this.ruleForm.ability_make) this.ruleForm.ability_make=[];
  //       // if(this.ruleForm.honor && this.ruleForm.honor.length>0)
  //     },
  //     deep:true
  //   }
  // },
  async created() {
    this.ruleForm = this.relation || {};
    if (!this.ruleForm.ability_make) this.ruleForm.ability_make = [];
  },
  mounted() {
    // Object.assign(this.ruleForm,this.item);
    // console.log("111",this.ruleForm);
    // console.log(this.item);
  },
  methods: {
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit("change", this.ruleForm);
            resolve(true);
          } else {
            return reject();
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>

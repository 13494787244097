<template>
  <div class="zt-block">
    <div class="zt-block-content flex flex-justify-between">
      <div
        class="flex flex-align-center"
        style="color:rgba(128,128,128,1);font-size:16px;"
      >
        完善企业资质,让更多的人关注你
      </div>
      <div style="color:rgba(77,77,77,1);font-weight:bold;font-size:18px;">
        当前完善率：<span style="color:rgba(43,186,174,1);">{{ score }}%</span>
      </div>
      <div style="font-size:20px;font-weight:bold;">
        <span v-if="stat == 0" style="color:#eee;">未提交</span>
        <span v-if="stat == 1" style="color:#016FFF;">审核中</span>
        <span v-if="stat == 2" style="color:#2BBAAE;">审核已通过</span>
        <span
          v-if="stat == 3"
          style="color:#F66F6A;cursor:pointer;"
          @click="seeCause"
          >审核未通过(查看原因)</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["score", "stat", "msg"],
  data() {
    return {};
  },
  async created() {},
  methods: {
    seeCause() {
      // this.$message.error(this.msg||'不通过');
      this.$alert(this.msg || "不通过", {
        title: "不通过原因",
        confirmButtonText: "确定",
        center: true
      });
    }
  }
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>

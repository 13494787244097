<template>
  <div class="zt-block">
    <div class="zt-block-head">联系方式</div>
    <div class="zt-block-content">
      <el-form
        v-if="ruleForm"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item label="联系人(客服)" prop="name">
          <el-input v-model="ruleForm.name" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="联系号码" prop="mobile">
          <el-input v-model="ruleForm.mobile" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  components: { },
  model: {
    prop: "contact",
    event: "change",
  },
  props: ["contact"],
  data() {
    return {
      ruleForm: null,
      rules: {
        // intro: [{ required: true, message: '请输入项目描述', trigger: 'blur' }],
        // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
      },
    };
  },
  watch: {
    // "xz":{
    //   handler(newName, oldName) {
    //     Object.assign(this.ruleForm,this.xz);
    //   },
    //   deep:true
    // }
  },
  async created() {
    // Object.assign(this.ruleForm,this.xz);
    // console.log(123,this.ruleForm,this.xz);
    this.ruleForm = this.contact || {};
  },
  // mounted() {
  //   // this.ruleForm = this.xz || {};
  //   // Object.assign(this.ruleForm,this.item);
  //   // console.log("111",this.ruleForm);
  //   // console.log(this.item);
  // },
  methods: {
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid, obj) => {
          if (valid) {
            this.$emit("change", this.ruleForm);
            resolve(true);
          } else {
            return reject(new Error(obj[Object.keys(obj)[0]][0].message));
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>

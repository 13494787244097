<template>
  <div v-if="tags">
    <template v-for="(tag,idx) in tagList">
      <el-tag :key="idx" @click="select(tag)" class="tag" :effect="tag.selected ? 'dark':'plain'" >{{tag.v}}</el-tag>
    </template>
    
  </div>
</template>

<script>
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
export default {
  model: {
    prop: "values",
    event: "change"
  },
  props: {
    values: Array,
    k: String,
    v: String,
    tags: Array,
    limit:Number
  },
  data() {
    return {
        tagList:[]
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  created: function() {
      
      this.tags.forEach(tag=>{
        let obj = cloneDeep(tag);
        let exist = this.values.find(r=>{
          return r.k==tag.k;
        });
        obj.selected = exist ? true:false;
        this.tagList.push(obj);
      });
      // console.log(123,this.tags,this.values,this.tagList);
  },
  methods: {
    select(tag) {
      if(this.limit && tag.selected==false){
        let selectedCount = this.tagList.reduce((pre,cur)=>{
          if(cur.selected) pre++;
          return pre;
        },0);
        if(selectedCount>=this.limit){
          this.$message.error(`最多选择${this.limit}个`);
          return;
        }
      }
      tag.selected=!tag.selected;
      let selectValues = this.tagList.reduce((pre,cur)=>{
          let dd = clone(cur);
          if(dd.selected){
            delete dd.selected;
            pre.push(dd);
          }
          return pre;
      },[]);
      this.$emit("change", selectValues);
    }
  }
};
</script>
<style scoped>
.el-tag {
  width: 108px;
  text-align: center;
}
.el-tag--dark {
  background: #fff;
  border-color: #016FFF;
  color: #016FFF;
}
.el-tag--plain {
  border-color: #808080;
  color: #808080;
}
.tag:hover {
  cursor: pointer;
}
.tag:not(:last-child){
  margin-right: 5px;
}
</style>